import {ORDER_CODES, ORDER_TYPE, OrderModel} from '@matchsource/models/order';
import {SearchStateModel} from '@matchsource/models/search-states';
import {DiagnosisModel} from '@matchsource/models/patient';
import {AlertType, NotificationsAlertBasicModel, RaceEthnicityUpdatedModel} from '@matchsource/models/workflow-manager';
import {CordListModel} from '@matchsource/models/cord';
import {BiobankListModel} from '@matchsource/models/biobank';
import {DonorContactReportMetadataModel, OplPanelModel} from '@matchsource/models/opl';
import {DonorListModel} from '@matchsource/models/donor';
import {HlaMapModel} from '@matchsource/models/hla';
import {PatientInfoModel, TCInfoModel} from '@matchsource/models/patient-extended';

export enum WorkflowManagerRoutes {
  Workflow = 'workflow',
  WorkflowSelectBp = 'workflow.select-bp',
  WorkflowBp = 'workflow.bp',
  WorkflowBpOverview = 'workflow.bp.overview',
}

export enum WorkflowNotificationType {
  High = 'high',
  Workup = 'workup',
  All = 'all',
}

export enum AlertWideGroupType {
  Order = 'order',

  Patient = 'patient',

  SourceMatching = 'source-matching',

  DonorOutreachUpdated = 'donor-outreach-updated',

  CriticalAnomalyResolved = 'critical-anomaly-resovled',
}

export interface PatientAlert extends NotificationsAlertBasicModel {
  appointmentDate?: MsApp.DateString;
  id: number;
  children?: PatientAlert[];
  prevAppointmentDate?: MsApp.DateString;
  serviceDesc?: string;
  stageCode?: string;
  stageName?: string;
  stageLabel?: string;
  valueDate?: MsApp.DateString;
  collectionDate?: MsApp.DateString;
  prepDate?: MsApp.DateString;
  subsequent?: boolean;
  source?: any;
  matching?: any;
  sourceType?: any;
  order?: OrderModel;
  opl?: OplPanelModel;
  report?: DonorContactReportMetadataModel;
  raceEthnicityUpdated?: RaceEthnicityUpdatedModel;
}

export type AlertWideGroupBase = {
  id: string;
  type: AlertWideGroupType;
  lastUpdated: MsApp.DateString;
  alerts: PatientAlert[];
};

export type PatientAlertWideGroup = AlertWideGroupBase & {
  type: AlertWideGroupType.Patient;
};

export type SourceMatchingAlertWideGroup = AlertWideGroupBase & {
  type: AlertWideGroupType.SourceMatching;
  source?: CordListModel | DonorListModel | BiobankListModel;
};

export type CriticalAnomalyResolvedAlertWideGroup = AlertWideGroupBase & {
  type: AlertWideGroupType.CriticalAnomalyResolved;
  source?: CordListModel | DonorListModel;
};

export type DonorOutreachUpdatedAlertWideGroup = AlertWideGroupBase & {
  type: AlertWideGroupType.DonorOutreachUpdated;
  source: DonorListModel;
};

export type OrderAlertWideGroup = AlertWideGroupBase & {
  type: AlertWideGroupType.Order;
  order: OrderModel;
  source?: CordListModel | DonorListModel | BiobankListModel;
};

export type AlertWideGroup =
  | PatientAlertWideGroup
  | OrderAlertWideGroup
  | SourceMatchingAlertWideGroup
  | CriticalAnomalyResolvedAlertWideGroup
  | DonorOutreachUpdatedAlertWideGroup;

export interface ExtendedPatientInfo {
  patient: {
    info: PatientInfoModel;
    tcInfo: TCInfoModel;
    id: MsApp.Guid;
    diagnosis: DiagnosisModel;
    nmdpId: MsApp.Guid;
    status: string;
    statusName: string;
  };
  patientHla: HlaMapModel;
  phenotypes: SearchStateModel[];
}

export const WORKUP_ALERTS: AlertType[] = [
  AlertType.HpcMarrowApptDate,
  AlertType.HpcApheresisApptDate,
  AlertType.MncApheresisApptDate,
  AlertType.NcWholeBloodApptDate,
  AlertType.AdditionalTubesApptDate,
  AlertType.AdditionalTubesApptDateRemoved,
  AlertType.EnterPrepDate,
  AlertType.DonorWorkupReleased,
  AlertType.EnterResampDate,
  AlertType.SubmitVerificationForm,
  AlertType.Shipment,
  AlertType.CordShipmentReleased,
  AlertType.HpcMarrowApptDateRemoved,
  AlertType.HpcApheresisApptDateRemoved,
  AlertType.MncApheresisApptDateRemoved,
  AlertType.NcWholeBloodApptDateRemoved,
  AlertType.ShipmentRemoved,
];

export const WORKUP_ALERTS_ORDER_TYPES = [
  ORDER_TYPE.WU.type,
  ORDER_TYPE.PBSC.type,
  ORDER_TYPE[ORDER_CODES.MNC_APHERESIS].type,
  ORDER_TYPE.WB.type,
  ORDER_TYPE.AT.type,
  ORDER_TYPE.OR.type,
];

export enum FindByType {
  Name = 'name',
  Rid = 'rid',
}

export enum RidNameSearchTestIds {
  RidNameSearchTerm = 'rid-name-search-term',
  RidNameSearchButton = 'rid-name-search-button',
  RidNameClearButton = 'rid-name-clear-button',
  ErrorContainer = 'error-container',
}

export enum WorkflowManagerAlertIds {
  EnterRessempDate = 'enter-ressemp-date',
  InitialPtReceivedEditPatient = 'initial-pt-received-edit-patient',
}
