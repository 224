import {SourceTypes} from '@matchsource/models/source';

export enum AlertType {
  CtAppointmentDate = 'CTAPPTDTE',
  CusAppointmentDate = 'CUSAPPTDTE',
  ConfirmationSourceRequested = 'ALLSRCACT',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ChangeCustomizedAppointmentDate = 'CUSAPPTDTE',
  Shipment = 'CRDSHDTE',
  CordCtShipmentDate = 'CRDAPPTDTE',
  HpcMarrowApptDate = 'HPCMARDTE',
  HpcApheresisApptDate = 'HPCAPHDTE',
  MncApheresisApptDate = 'MNCAPHDTE',
  NcWholeBloodApptDate = 'NCWBDTE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ChangeCordShipmentDate = 'CRDAPPTDTE',
  DonorStatusChangedTuToAv = 'TUDNRAV',
  CusResultsRes = 'CUSREREC',
  Dbr1ResultsRes = 'DRB1REC',
  CordCtResultsRes = 'CRDRESREC',
  AdditionalTubesApptDate = 'ADDTUBDTE',
  EnterPrepDate = 'ENTPREPDTE',
  EnterResampDate = 'RESAMPDTE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ResearchSampleDateMissing = 'RESAMPDTE',
  PatientTransferOut = 'PTRANSFOUT',
  PatientTransferIn = 'PTRANSFIN',
  ConfirmPatientIdentityVerification = 'CHK_PT_ID',
  SubmitVerificationForm = 'SUBVERFRM',
  PotentialDonorMatch = 'DNRPAM',
  CtCordReleased = 'CRDRELCT',
  CtDonorReleased = 'DNRRELCT',
  CusDonorReleased = 'DNRRELCUS',
  Drb1DonorReleased = 'DRB1REL',
  HoldCordReleased = 'CRDRELH',
  CordShipmentReleased = 'CRDRELSH',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ShipmentDate = 'CRDSHDTE',
  DonorWorkupReleased = 'WORKDNRREL',
  PotentialCordMatch = 'CRDDRB1M',
  InitailPatientTypingReceived = 'INITPTR',
  InitailPatientTypingReceivedNotSubmitted = 'INITPTRNOTSUB',
  SsaRequestMakePublic = 'SSAPUBL',
  HlaNotReceived = 'PTRNOTRECD',
  ElectricPatientRecord = 'ELECPTRECD',
  CryoShipDte = 'CRYOSHPDTE',
  CommunityPracticePatientSubmitted = 'CPPTCRTD',
  DonorVaccinationDetailsUpdated = 'DNRVACSTATUS',
  CreatePatientReferencePlan = 'CREATERFRLPLAN',
  AdultCryopreservedProduct = 'PADCPM',
  DonorOutreachCompleted = 'DNROUTREACH',
  DonorOutreachUpdate = 'DNRUPDATE',
  CryoShipDteRemoved = 'RMCRYOSHPDTE',
  CtAppointmentDateRemoved = 'RMCTAPPTDTE',
  CusAppointmentDateRemoved = 'RMCUSAPPTDTE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ChangeCustomizedAppointmentDateRemoved = 'RMCUSAPPTDTE',
  HpcMarrowApptDateRemoved = 'RMHPCMARDTE',
  HpcApheresisApptDateRemoved = 'RMHPCAPHDTE',
  MncApheresisApptDateRemoved = 'RMMNCAPHDTE',
  NcWholeBloodApptDateRemoved = 'RMNCWBDTE',
  CordCtShipmentDateRemoved = 'RMCRDAPPTDTE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ChangeCordShipmentDateRemoved = 'RMCRDAPPTDTE',
  ShipmentRemoved = 'RMCRDSHDTE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ShipmentDateRemoved = 'RMCRDSHDTE',
  AdditionalTubesApptDateRemoved = 'RMADDTUBDTE',
  RaceEthnicityUpdated = 'PTRCETNUPD',
  CriticalAnomalyResolved = 'CRITANOMRSLVD',
}

export type AlertSourceType = SourceTypes.Donor | SourceTypes.Cord | SourceTypes.Biobank;

export const ALERTS_SOURCE_TYPE_MAP: Partial<Record<AlertType, AlertSourceType>> = {
  [AlertType.DonorOutreachUpdate]: SourceTypes.Donor,
  [AlertType.PotentialDonorMatch]: SourceTypes.Donor,
  [AlertType.DonorStatusChangedTuToAv]: SourceTypes.Donor,
  [AlertType.PotentialCordMatch]: SourceTypes.Cord,
  [AlertType.AdultCryopreservedProduct]: SourceTypes.Biobank,
};

export const CRITICAL_ANOMALY_ALERTS: AlertType[] = [AlertType.CriticalAnomalyResolved];

export const PATIENT_OWN_ALERTS: AlertType[] = [
  AlertType.PatientTransferOut,
  AlertType.PatientTransferIn,
  AlertType.InitailPatientTypingReceived,
  AlertType.InitailPatientTypingReceivedNotSubmitted,
  AlertType.SsaRequestMakePublic,
  AlertType.HlaNotReceived,
  AlertType.ConfirmPatientIdentityVerification,
  AlertType.ElectricPatientRecord,
  AlertType.CommunityPracticePatientSubmitted,
  AlertType.CreatePatientReferencePlan,
  AlertType.DonorOutreachCompleted,
];

export const HAS_MATCHING_ALERTS: AlertType[] = [
  AlertType.PotentialCordMatch,
  AlertType.CordCtResultsRes,
  AlertType.CusResultsRes,
  AlertType.PotentialDonorMatch,
  AlertType.Dbr1ResultsRes,
  AlertType.AdultCryopreservedProduct,
  AlertType.DonorStatusChangedTuToAv,
];

export const HAS_ORDERABLE_ALERTS: AlertType[] = [
  AlertType.AdditionalTubesApptDate,
  AlertType.ConfirmationSourceRequested,
  AlertType.ChangeCordShipmentDate,
  AlertType.CtCordReleased,
  AlertType.HoldCordReleased,
  AlertType.ShipmentDate,
  AlertType.CordShipmentReleased,
  AlertType.CryoShipDte,
  AlertType.CtAppointmentDate,
  AlertType.CusAppointmentDate,
  AlertType.CtDonorReleased,
  AlertType.CusDonorReleased,
  AlertType.Drb1DonorReleased,
  AlertType.EnterPrepDate,
  AlertType.HpcApheresisApptDate,
  AlertType.HpcMarrowApptDate,
  AlertType.MncApheresisApptDate,
  AlertType.NcWholeBloodApptDate,
  AlertType.EnterResampDate,
  AlertType.SubmitVerificationForm,
  AlertType.DonorWorkupReleased,
  AlertType.PotentialCordMatch,
  AlertType.DonorStatusChangedTuToAv,
  AlertType.PotentialDonorMatch,
  AlertType.AdultCryopreservedProduct,
  AlertType.Dbr1ResultsRes,
  AlertType.CusResultsRes,
  AlertType.CordCtResultsRes,
  AlertType.NcWholeBloodApptDateRemoved,
  AlertType.MncApheresisApptDateRemoved,
  AlertType.HpcMarrowApptDateRemoved,
  AlertType.HpcApheresisApptDateRemoved,
  AlertType.RaceEthnicityUpdated,
];

export type PatientListOrderType = 'asc' | 'desc';

export interface PatientsOrderType {
  field: string;
  direction: PatientListOrderType;
  label: string;
  value: string;
}

export interface NotificationsAlertBasicModel {
  bpGuid: MsApp.Guid;
  creationDate: MsApp.DateTimeString;
  highPriority: boolean;
  notificationCode: AlertType;
  notificationName: string;
  notificationTypeCode: string;
  oplTrackingId: number | undefined;
  orderGuid: string;
  orderLineItemId: number;
  orderTrackingIds: number[];
  sourceGuid: string;
  statusCode: string;
  subjectGuid: string;
  serviceCode: string | null;
  raceEthnicityHistoryId: number;
}

export interface NotificationsAlertsModel extends NotificationsAlertBasicModel {
  id: number;
}

export interface NotificationsAlertsEventModel {
  id: number;
  sourceGuid: string;
}

interface RaceEthnModel {
  primRace?: string;
  raceCodes?: string;
  ethnicity?: string;
}

export interface RaceEthnicityHistoryModel {
  id?: number;
  prevRaceEthn?: RaceEthnModel;
  currRaceEthn?: RaceEthnModel;
}

export interface RaceModel {
  ethnicity: string;
  races: string;
}

export interface RaceEthnicityUpdatedModel {
  prevRaceEthn: RaceModel;
  currRaceEthn: RaceModel;
}
